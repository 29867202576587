<template>
  <h1>诚邀加盟页面！</h1>
</template>

<script>
export default {

}
</script>

<style>
</style>